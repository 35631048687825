import React, { Suspense, lazy, useState, useEffect, createContext } from 'react';
import { Outlet } from 'react-router-dom';
import { GlobalStyle } from './styles/StyledComponents';
import Navigation from './components/Navigation';
import { detectUserLocation } from './services/locationService';
import { Helmet } from 'react-helmet';
import { initGA } from './utils/analytics';

export const LocationContext = createContext();

// const HomePage = lazy(() => import('./pages/HomePage'));
// const ExamPage = lazy(() => import('./pages/ExamPage'));
// const MockTestPage = lazy(() => import('./pages/MockTestPage'));
// const ResultsPage = lazy(() => import('./pages/ResultsPage'));
// const LoginPage = lazy(() => import('./pages/LoginPage'));
// const SignupPage = lazy(() => import('./pages/SignupPage'));
// const DataUploadPage = lazy(() => import('./pages/DataUploadPage'));
// // const ProfilePage = lazy(() => import('./pages/ProfilePage'));

function App() {
  const [userLocation, setUserLocation] = useState('US');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initGA();
  }, []);

  useEffect(() => {
    const initializeLocation = async () => {
      const detectedLocation = await detectUserLocation();
      setUserLocation(detectedLocation);
      setIsLoading(false);
    };
    initializeLocation();
  }, []);

  // useEffect(() => {
  //   const locationBasedMeta = {
  //     'US': {
  //       title: 'US Test Prep - SAT, ACT, AP Exam Practice | MockTestPro',
  //       description: 'Prepare for SAT, ACT, AP exams with comprehensive study materials and practice tests. Get ready for US college admissions.',
  //       keywords: 'SAT prep, ACT practice, AP exams, US college admissions'
  //     },
  //     'IN': {
  //       title: 'India Exam Prep - SSC, IBPS, UPSC Practice | MockTestPro',
  //       description: 'Comprehensive preparation for SSC, IBPS, UPSC and other government exams in India. Practice tests and study materials.',
  //       keywords: 'SSC preparation, IBPS practice, UPSC exam prep'
  //     }
  //   };

  //   const meta = locationBasedMeta[userLocation] || locationBasedMeta['US'];
  //   document.title = meta.title;
  //   document.querySelector('meta[name="description"]').content = meta.description;
  //   document.querySelector('meta[name="keywords"]').content = meta.keywords;
  // }, [userLocation]);
  
  const defaultMeta = {
    'US': {
      title: 'MockTestPro | Free Online Practice Tests & Study Materials',
      description: 'Prepare for your exams with comprehensive practice tests and study materials. Get ready for success with MockTestPro.',
      keywords: 'sat prep, act prep, sat mock tests, act mock tests, sat practice tests, sat exam preparation, act exam preparation, sat study materials, act study materials, sat online tests, act online tests'
    },
    'IN': {
      title: 'MockTestPro | India\'s Leading Online Test Preparation Platform',
      description: 'Comprehensive test preparation platform for competitive exams in India. Practice tests, study materials, and expert guidance.',
      keywords: 'competitive exams, government jobs, entrance tests, mock tests, online preparation'
    }
  };

  // const meta = defaultMeta[userLocation] || defaultMeta['US'];

  return (
    <LocationContext.Provider value={{userLocation, setUserLocation, isLoading}}>
      {/* <Helmet defaultTitle={meta.title}>
        <meta name="description" content={meta.description} />
        <meta name="keywords" content={meta.keywords} />
      </Helmet> */}
      <GlobalStyle />
      <Navigation />
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
      </LocationContext.Provider>
  );
}

export default App;